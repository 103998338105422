import React from "react";
import "./scrollup.css";

const ScrollUp = () => {
  return (
    <a href="#" className="scrollup">
      <i className="uil uil-arrow-up scrollup__icon"></i>
    </a>
  );
};

export default ScrollUp;
